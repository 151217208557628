import React from 'react'

function Music() {
  return (
    <div className='w-80' style={{width: '80%'}}>
        {/* <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/1qFp8zDvsXyCsC5dqz8X4S?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
        {/* <iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/4Ge5SDFoVt0btiaTIj8E5E?utm_source=generator&theme=0" width="40%" height="352" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe> */}
        {/* <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/26ICTgRX0DdHzKInBkIR3B?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe> */}
        {/* <iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/4Ge5SDFoVt0btiaTIj8E5E?utm_source=generator" width="10%" height="20" frameBorder="0" allowfullscreen="false" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe> */}
    </div>
  )
}

export default Music